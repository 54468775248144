import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import Logo from './assets/icons/logo.png';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="content">
          <div className="title-holder">
            <h1>DONO</h1>
            <p>Revolutionizing non-profit donation processing and outreach. Shoot us an email if you're curious.</p>
          </div>
          <a href="mailto:dono@mit.edu">
            <div className="cta">Send us an email</div>
          </a>
        </div>
      </div>
    );
  }
}

export default App;